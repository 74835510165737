import q from 'qjuul'
import React from 'react'

function NotImplemented() {
  return (
    <q.div f1 h100 w100 fccc>
        <q.div>Not implemented yet</q.div>
    </q.div>
  )
}

export default NotImplemented